import { Skeleton, Row, Col, Modal } from "antd";
import { PaymentStatusTag } from "components";
import { RenderIf, InfoRow } from "components/Common";
import {
  useDecidePaymentRequest,
  useGetPaymentRequest,
} from "pages/Payments/api";
import {
  usePaymentDetailsModalActions,
  usePaymentDetailsModalState,
} from "pages/Payments/store";

export const PaymentDetailsModal = () => {
  const { isOpen, id, onlyView } = usePaymentDetailsModalState();
  const { close } = usePaymentDetailsModalActions();

  const { mutateAsync: decidePaymentRequest } = useDecidePaymentRequest({
    refetchQueries: ["useGetPaymentRequests"],
  });

  const handleApprove = () => {
    decidePaymentRequest({ id: id!, status: "approved" });
  };

  const handleReject = () => {
    decidePaymentRequest({ id: id!, status: "rejected" });
  };

  const { data: payment, isLoading } = useGetPaymentRequest(
    { id: id! },
    { enabled: isOpen }
  );

  return (
    <Modal
      open={isOpen}
      onCancel={close}
      title="Payment Detail"
      destroyOnClose
      width={600}
      okText="Approve"
      cancelText="Cancel"
      onOk={handleApprove}
      footer={onlyView ? null : undefined}
    >
      <RenderIf
        condition={!isLoading && !!payment}
        fallback={<Skeleton active paragraph={{ rows: 4 }} />}
      >
        <Row justify="center" gutter={[16, 16]}>
          <Col span={24}>
            <InfoRow
              text="Employee"
              content={payment?.user?.name + " " + payment?.user?.surname}
            />
          </Col>
          {/*  <Col span={12}>
            <InfoRow
              text="Priorty"
              content={<PriortyStatusTag status={payment?.priority} />}
            />
          </Col> */}
          <Col span={24}>
            <InfoRow text="Description" content={payment?.description} />
          </Col>
          <Col span={12}>
            <InfoRow text="Reason" content={payment?.payment_type?.name} />
          </Col>

          <Col span={12}>
            <InfoRow
              text="Status"
              content={<PaymentStatusTag status={payment?.status} />}
            />
          </Col>
        </Row>
      </RenderIf>
    </Modal>
  );
};
