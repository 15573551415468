import { axiosMain } from "lib/axios";
import { ExtractFnReturnType, QueryConfig } from "lib/react-query";
import { useQuery } from "@tanstack/react-query";
import { LeaveDaysKeys } from "../keys";
import { LeaveType, EmployeeLeaveDaysStatus, Employee } from "types";

export type EmployeeLeaveDaysPayload = {
  query?: string;
  employeeId?: number;
  startDate?: string;
  endDate?: string;
};
type EmployeeLeaveDaysResponse = {
  id: number;
  employee: Employee;
  totalAnnualLeaveDays: number;
  leave_type: LeaveType;
  start_date: Date;
  end_date: Date;
  days: number;
  status: EmployeeLeaveDaysStatus;
  description: string;
  file: string;
  desicion_by: number;
  desicion_date: Date;
  remaining_days: number;
  replacement_user_id: number;
  return_date: Date;
  created_at: Date;
  updated_at: Date;
};

const getEmployeeLeaveDays = async ({
  employeeId,
  startDate,
  endDate,
  query,
}: EmployeeLeaveDaysPayload) => {
  const response: any = (
    await axiosMain.get("/employees/leave-days", {
      params: {
        startDate: startDate || undefined,
        endDate: endDate || undefined,
        query: query || undefined,
        employee_id: employeeId || undefined,
      },
    })
  ).data;

  return response;
};

type QueryFnType = typeof getEmployeeLeaveDays;

export const useGetEmployeeLeaveDays = (
  payload: EmployeeLeaveDaysPayload,
  config?: QueryConfig<QueryFnType>
) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: LeaveDaysKeys.getEmployeeLeaveDays(payload),
    queryFn: ({ queryKey }) => {
      const [_, payload] = queryKey as ReturnType<
        typeof LeaveDaysKeys.getEmployeeLeaveDays
      >;
      return getEmployeeLeaveDays(payload);
    },
  });
};
