import {
  BankOutlined,
  LeftOutlined,
  RightOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { css } from "@emotion/react";
import {
  Avatar,
  Button,
  Divider,
  Flex,
  Image,
  Layout,
  Row,
  Space,
  Tooltip,
  Typography,
  theme,
} from "antd";
import Sider from "antd/es/layout/Sider";
import { Buddy } from "assets/logo";
import { useState } from "react";

import { UserDropdown } from "../Header/UserDropdown";
import { RenderIf } from "components/Common";
import { Logo } from "assets/Icons";
import storage from "utils/storage";
import { useUser } from "lib/auth";
import Menu from "./partials/Menu";
import { useCommonStoreState } from "stores";

const { Text } = Typography;

const companyDropdown = [
  {
    label: (
      <Flex gap={8} align="center">
        <SettingOutlined />
        <Text>Settings</Text>
      </Flex>
    ),
    key: "company",
  },
];

type PageSidebarProps = {
  handleSidebarToggle?: () => void;
};
//TODO: make css styles for the sidebar
export const PageSidebar = ({ handleSidebarToggle }: PageSidebarProps) => {
  const { isDarkMode } = useCommonStoreState();
  const { data: user } = useUser();
  const isMobile = window.innerWidth < 1400;
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const [collapsed, setCollapsed] = useState(
    storage.getItem("sidebarCollapsed") === "true" ? true : false
  );

  const handleCollapse = () => {
    storage.setItem("sidebarCollapsed", JSON.stringify(!collapsed));
    setCollapsed(!collapsed);
  };

  return (
    <Sider
      style={{
        background: isDarkMode ? "#2d2d2d" : "white",
      }}
      trigger={null}
      collapsible={!isMobile}
      collapsed={!isMobile && collapsed}
      css={(theme: any) => css`
        background: ${isDarkMode ? "#2d2d2d" : theme.colorWhite};
      `}
      width={"260px"}
      collapsedWidth={"68px"}
    >
      <Layout
        css={(theme: any) => css`
          height: 100vh;
          position: relative;
          background: ${isDarkMode ? "#2d2d2d" : theme.colorWhite};
          display: flex;
          padding: 20px 10px;
        `}
      >
        <Row
          justify={"space-between"}
          wrap={false}
          css={(theme: any) => css`
            width: 100%;
            padding: 0px 10px;
            background: ${isDarkMode ? "#2d2d2d" : theme.colorWhite};
            z-index: 1;
          `}
        >
          <RenderIf
            condition={!collapsed}
            fallback={
              <Buddy
                style={{
                  width: "68px",
                  height: "30px",
                  objectFit: "contain",
                }}
              />
            }
          >
            <Logo
              css={css`
                width: 100px;
              `}
            />
          </RenderIf>

          <RenderIf condition={!collapsed || isMobile}>
            <Space size={14}>
              <UserDropdown user={user} />
            </Space>
          </RenderIf>
        </Row>

        <RenderIf condition={!collapsed}>
          <Space
            size={14}
            css={css`
              visibility: ${collapsed ? "hidden" : "visible"};
              width: 100%;
              padding: 16px;
              margin-top: 20px;
              cursor: pointer;

              .ant-space-item:last-child {
                width: 100%;
              }
            `}
          >
            <RenderIf
              condition={!!user?.company?.logo}
              fallback={
                <Avatar
                  icon={
                    <BankOutlined
                      style={{
                        fontSize: "20px",
                      }}
                    />
                  }
                  size={32}
                  css={(theme: any) => {
                    return css`
                      background: transparent;
                      border: 1px solid ${theme.colorTextPlaceholder};
                      color: ${theme.colorTextPlaceholder};
                    `;
                  }}
                />
              }
            >
              <Image
              preview={false}
                css={css`
                  object-fit: cover;
                  height: 30px !important;
                  width: 30px !important;
                `}
                src={user?.company?.logo}
              />
            </RenderIf>

            <Flex
              justify="space-between"
              align="center"
              style={{ width: "100%" }}
              css={css`
                animation: ${collapsed ? "none" : "show 3s"};
                @keyframes show {
                  from {
                    visibility: hidden !important;
                  }
                  to {
                    visibility: visible 100% !important;
                  }
                }
              `}
            >
              <Flex vertical gap={0} wrap={"nowrap"}>
                <Text
                  type="secondary"
                  style={{ fontSize: "12px" }}
                  css={css`
                    white-space: nowrap !important;
                  `}
                >
                  Company
                </Text>
                <Text
                  strong
                  style={{ fontSize: "14px" }}
                  css={css`
                    white-space: nowrap !important;
                  `}
                >
                  {user?.company?.name}
                </Text>
              </Flex>
            </Flex>
          </Space>
        </RenderIf>
        <RenderIf condition={collapsed}>
          <Tooltip title={user?.company?.name} placement="right">
            <Space
              size={14}
              css={css`
                width: 100%;
                padding: 5px 10px;
                margin-top: 10px;
                cursor: pointer;

                .ant-space-item:last-child {
                  width: 100%;
                }
              `}
            >
              <RenderIf
                condition={!!user?.company?.logo}
                fallback={
                  <Avatar
                    icon={
                      <BankOutlined
                        style={{
                          fontSize: "20px",
                        }}
                      />
                    }
                    size={32}
                    css={(theme: any) => {
                      return css`
                        background: transparent;
                        border: 1px solid ${theme.colorTextPlaceholder};
                        color: ${theme.colorTextPlaceholder};
                      `;
                    }}
                  />
                }
              >
                <Image
                   preview={false}
                  css={css`
                    object-fit: cover;
                    height: 30px !important;
                    width: 30px !important;
                  `}
                  src={user?.company?.logo}
                />
              </RenderIf>
            </Space>
          </Tooltip>
        </RenderIf>

        <Divider
          style={{
            margin: "0px",
          }}
        />
        <Menu user={user} handleSidebarToggle={handleSidebarToggle} />
        <RenderIf condition={!isMobile}>
          <Button
            type="text"
            shape="circle"
            ghost={false}
            icon={
              collapsed ? (
                <RightOutlined
                  style={{
                    color: colorPrimary,
                  }}
                />
              ) : (
                <LeftOutlined
                  style={{
                    color: colorPrimary,
                  }}
                />
              )
            }
            onClick={handleCollapse}
            css={(theme: any) => css`
              background: ${isDarkMode ? "#2d2d2d" : theme.colorWhite};
              color: ${theme.colorTextBase};
              font-size: 16px;
              position: absolute;
              bottom: 40%;
              right: -10px;
            `}
          />
        </RenderIf>
      </Layout>
    </Sider>
  );
};
