import dayjs from "dayjs";

export const addWorkdays = (startDate: dayjs.Dayjs, workdays: number) => {
  let date = startDate.clone();
  while (workdays > 0) {
    date = date.add(1, "day");
    // Skip Saturdays and Sundays
    if (date.day() !== 0 && date.day() !== 6) {
      workdays -= 1;
    }
  }
  return date;
};

export const countWeekdays = (start?: dayjs.Dayjs, end?: dayjs.Dayjs) => {

  if(!start || !end) {
    return 0;
  }
  let count = 0;
  let current = start.clone();

  while (current.isBefore(end) || current.isSame(end, "day")) {
    // Skip Saturdays (day 6) and Sundays (day 0)
    if (current.day() !== 0 && current.day() !== 6) {
      count += 1;
    }
    current = current.add(1, "day");
  }

  return count;
};
