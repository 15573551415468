import { InfoCircleOutlined } from "@ant-design/icons";
import { css, Theme } from "@emotion/react";
import { Typography, Flex, Tooltip, Skeleton } from "antd";
import { RenderIf } from "../RenderIf";
import { useCommonStoreState } from "stores";

type InfoRowProps = {
  text: string | React.ReactNode;
  content: string | React.ReactNode;
  tooltip?: {
    content: string;
  };
  loading?: boolean;
};

const { Text } = Typography;

const WrapperCSS = (theme: Theme, isDarkMode?: boolean) => css`
  padding: 18px;
  border-radius: 8px;
  background-color: ${isDarkMode ? "#000000" : "#fafafa"};

  .row-text {
    color: ${theme.colorTextBase};
  }
`;

const InfoRowSkeleton = ({ isDarkMode }: any) => {
  return (
    <Flex
      justify="space-between"
      align="center"
      css={(theme: any) => WrapperCSS(theme, isDarkMode)}
    >
      <Skeleton
        title={{
          width: "100%",
        }}
        paragraph={{
          rows: 0,
          width: "100%",
        }}
        css={css`
          width: 10% !important;
        `}
        active={true}
      />

      <Skeleton
        css={css`
          width: 40% !important;
        `}
        title={false}
        paragraph={{
          rows: 2,
          width: "100%",
        }}
        active={true}
      />
    </Flex>
  );
};

export const InfoRow = ({ text, content, tooltip, loading }: InfoRowProps) => {
  const { isDarkMode } = useCommonStoreState();
  return (
    <RenderIf
      condition={!loading}
      fallback={<InfoRowSkeleton isDarkMode={isDarkMode} />}
    >
      <Flex
        justify="space-between"
        align="center"
        css={(theme: any) => WrapperCSS(theme, isDarkMode)}
      >
        <Flex align="center" gap={4}>
          <Text className="row-text">{text}</Text>
          <RenderIf condition={!!tooltip}>
            <Tooltip title={tooltip?.content}>
              <InfoCircleOutlined />
            </Tooltip>
          </RenderIf>
        </Flex>
        <Text>{content ?? "-"}</Text>
      </Flex>
    </RenderIf>
  );
};
