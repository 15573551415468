import { axiosMain } from "lib/axios";
import { ExtractFnReturnType, QueryConfig } from "lib/react-query";
import { employeeKeys } from "./keys";
import { useQuery } from "@tanstack/react-query";
import { Employee } from "types";

export type GetEmployeesPayload = {
  query?: string;
  departmentId?: number;
  branchId?: number;
  without_current_user?: boolean;
  page?: number;
  limit?: number;
};

const getEmployees = async ({
  query,
  departmentId,
  branchId,
  without_current_user = true,
}: GetEmployeesPayload) => {
  const response: Employee[] = (
    await axiosMain.get("/employees", {
      params: {
        query: query || undefined,
        department_id: departmentId,
        branch_id: branchId,
        without_current_user: without_current_user,
      },
      
    })
  ).data;
  return response;
};

type QueryFnType = typeof getEmployees;

export const useGetEmployees = (
  payload?: GetEmployeesPayload,
  config?: QueryConfig<QueryFnType>
) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["employees", payload],
    queryFn: ({ queryKey }) => {
      const [_, payload] = queryKey as ReturnType<
        typeof employeeKeys.getEmployees
      >;
      return getEmployees(payload);
    },
  });
};
