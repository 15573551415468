import { useExtendedMutation } from "hooks";
import { axiosMain } from "lib/axios";
import { ExtractFnReturnType, MutationConfig } from "lib/react-query";

export type AddLeaveDayRequestPayload = {
  start_date: string;
  end_date: string;
  leavedays_id: number;
  replacement_user_id?: number;
  return_date?: string;
  description: string;
  employee_id?: number;
};

export type addLeaveDayRequest = ExtractFnReturnType<typeof addLeaveDayRequest>;

export const addLeaveDayRequest = async (
  payload: AddLeaveDayRequestPayload
) => {
  const filteredPayload = Object.fromEntries(
    Object.entries(payload).filter(
      ([, value]) => value !== undefined && value !== "" && value !== null
    )
  );

  return axiosMain.post("/leave-day-requests", filteredPayload);
};

export const useAddLeaveDaysRequest = (
  config?: MutationConfig<typeof addLeaveDayRequest>
) => {
  return useExtendedMutation({
    ...config,
    mutationFn: addLeaveDayRequest,
  });
};
