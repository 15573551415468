import { css } from "@emotion/react";
import { Tag } from "antd";
import { Indexable, PaymentRequestStatus } from "types";

type PaymentStatusTagProps = {
  status?: PaymentRequestStatus;
  text?: string;
};

export const PaymentStatusTag = ({ status }: PaymentStatusTagProps) => {
  const colors: Indexable<object> = {
    pending: "orange",
    approved: "green",
    rejected: "red",
  };

  return (
    <Tag
      css={css`
        text-transform: capitalize;
      `}
      color={colors[status!]}
    >
      {status}
    </Tag>
  );
};
