import { useExtendedMutation } from "hooks";
import { axiosMain } from "lib/axios";
import { MutationConfig } from "lib/react-query";

export type DecidePaymentRequestPayload = {
  id: number;
  status: "approved" | "rejected";
};

export const decidePaymentRequest = async (
  payload: DecidePaymentRequestPayload
) => {
  return axiosMain.put(
    `/payment-requests/change-status`,
    {
      status: payload.status,
      id: payload.id,
    }
  );
};

export const useDecidePaymentRequest = (
  config?: MutationConfig<typeof decidePaymentRequest>
) => {
  return useExtendedMutation({
    ...config,
    mutationFn: decidePaymentRequest,
  });
};
