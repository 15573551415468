import {
  ClockCircleOutlined,
  DollarOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Flex, FloatButton, Form, Tag, Typography } from "antd";
import { RenderIf } from "components/Common";
import { FormModal } from "components/FormModal";
import dayjs from "dayjs";
import { countWeekdays, addWorkdays } from "helpers";
import { useAddLeaveDaysRequest, useGetEmployees } from "pages/Employees";
import { useGetEmployeeLeaveDays } from "pages/Employees/api/LeaveDays/useGetEmployeeLeaveDays";
import { AdvangePaymentModal } from "pages/Payments/components/Modals/AdvancePayment";
import { useAdvangePaymentModalActions } from "pages/Payments/store/AdvancePaymentModal";
import { useState } from "react";

const { Text } = Typography;

const LeaveDaysRequestModal = ({ setOpen, open }: any) => {
  const [selectedLeaveDay, setSelectedLeaveDay] = useState<any>(null);
  const { data: leaveDays } = useGetEmployeeLeaveDays({});
  const { mutateAsync: addLeaveDayRequest } = useAddLeaveDaysRequest({
    successMessage: "Leave request has been sent successfully",
    errorMessage: "Something went wrong, please try again later",
    refetchQueries: ["leavedayRequests"],
  });
  const [form] = Form.useForm();

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleFormSubmit = async (values: any) => {
    addLeaveDayRequest(values);
  };

  
  const handleFormChange = (changedValues: any, allValues: any) => {
    if (!allValues.date_range) {
      form.setFieldsValue({ count_of_days: 0 });
      form.setFieldsValue({ return_date: undefined });
    }

    if (allValues.date_range) {
      const start = dayjs(allValues.date_range[0]);
      const end = dayjs(allValues.date_range[1]);

      /* find closest monday if end date is friday and set return date to the next monday */

      if (end.day() === 5) {
        const returnDate = end.add(3, "days").format("YYYY-MM-DD");
        form.setFieldsValue({ return_date: returnDate });
      } else {
        form.setFieldsValue({ return_date: end.format("YYYY-MM-DD") });
      }

      const countOfDays = countWeekdays(start, end);

      form.setFieldsValue({ count_of_days: countOfDays });

      /* * if leave_day_id changed reset  date_range, count_of_days, return_date */

      if (changedValues.leave_day_id) {
        form.setFieldsValue({ date_range: undefined });
        form.setFieldsValue({ count_of_days: 0 });
        form.setFieldsValue({ return_date: undefined });
      }
    }
  };

  const formFields: FormModal["formFields"] = [
    {
      type: "searchableSelect",
      label: "Leave Days",
      name: "leave_day_id",
      queryFn: useGetEmployeeLeaveDays,
      rules: [{ required: true, message: "" }],

      optionFormat: (option) => ({
        value: option.id,
        label: (
          <Flex align="center" justify="space-between">
            {option.leave_days.name}
            <RenderIf condition={!option.unlimited_days}>
              <Tag>
                <Text style={{ fontSize: 12 }} type="secondary">
                  {option.remaining_days} days remaining
                </Text>
              </Tag>
            </RenderIf>
          </Flex>
        ),
      }),
      placeholder: "Select leave days",
    },
    {
      type: "dateRange",
      label: "Date Range",
      rules: [{ required: true, message: "" }],
      name: "date_range",
      placeholder: "Select start date",
      minDate: dayjs().format("YYYY-MM-DD"),
      disabledDate: (current: any, info: any) => {
        const leaveDayId = form.getFieldValue("leave_day_id");
        if (!leaveDayId) {
          return current && current < dayjs().startOf("day");
        }

        const foundLeaveDay = leaveDays?.find((leaveDay: any) => {
          return leaveDay?.id == leaveDayId;
        });

        if (foundLeaveDay?.unlimited_days) {
          return current && current < dayjs().startOf("day");
        }

        if (foundLeaveDay?.remaining_days === 0) {
          return current && current < dayjs().startOf("day");
        }

        if (foundLeaveDay?.remaining_days < 0) {
          return current && current < dayjs().startOf("day");
        }
        if (foundLeaveDay?.remaining_days > 0) {
          const start = dayjs().startOf("day");
          const end = addWorkdays(start, foundLeaveDay.remaining_days);

          return current && (current < start || current > end);
        }
        return false;
      },
      /*
      ! should be disabled by the remaining days of the selected leave day
      */
    },

    {
      type: "input",
      label: "Count of Days",
      name: "count_of_days",
      placeholder: "0",
      disabled: true,
    },

    {
      type: "searchableSelect",
      label: "Replacement User",
      name: "replacement_user_id",
      queryFn: useGetEmployees,
      params: { without_current_user: true },
      optionFormat: (option) => ({
        value: option.id,
        label: (
          <Flex align="center" gap={5}>
            <Text>{option.name}</Text>
            <Text>{option.surname}</Text>
          </Flex>
        ),
      }),
      placeholder: "Select replacement user",
    },
    {
      type: "date",
      label: "Return Date",
      name: "return_date",

      placeholder: "Select return date",
      disabled: true,
    },
    {
      type: "file",
      label: "File",
      name: "file",
      placeholder: "Upload file",
    },

    {
      type: "textarea",
      label: "Description",
      name: "note",
      placeholder: "Enter description",
      rows: 4,
    },
  ];

  return (
    <>
      <FormModal
        formFields={formFields}
        formInstance={form}
        onOk={handleFormSubmit}
        onCancel={handleModalClose}
        open={open}
        setOpen={setOpen}
        title="Request a leave"
        onValuesChange={handleFormChange}
      />
    </>
  );
};

export const Widget = () => {
  const [openLeaveDaysModal, setOpenLeaveDaysModal] = useState(false);
  const { open: openPaymentModal } = useAdvangePaymentModalActions();

  const handleModalOpen = () => {
    setOpenLeaveDaysModal(true);
  };

  const handlePaymentModalOpen = () => {
    openPaymentModal();
  };

  return (
    <>
      <FloatButton.Group
        trigger="click"
        type="primary"
        style={{ right: 24 }}
        icon={<PlusOutlined />}
      >
        <FloatButton
          icon={<ClockCircleOutlined />}
          onClick={handleModalOpen}
          tooltip="Request a leave"
        />

        <FloatButton
          icon={<DollarOutlined />}
          onClick={handlePaymentModalOpen}
          tooltip="Request advance payment"
        />
      </FloatButton.Group>
      <LeaveDaysRequestModal
        open={openLeaveDaysModal}
        setOpen={setOpenLeaveDaysModal}
      />

      <AdvangePaymentModal />
    </>
  );
};
