import forgetPasswordImage from "assets/auth/forget-password.png";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  ChangePasswordPayload,
  useChangePassword,
} from "../api/useChangePassword";
import { AuthLayout } from "../components/Layout";
import { css } from "@emotion/react";
import { Typography, Input, Button, Form } from "antd";

const { Text } = Typography;

export const ChangePassword = () => {
  const [searchParams] = useSearchParams();
  const [resetToken, setResetToken] = useState<string | null>();
  const [email, setEmail] = useState<string | null>();
  const navigation = useNavigate();
  const { mutateAsync, isLoading } = useChangePassword();

  useEffect(() => {
 
    if (searchParams.get("token") && searchParams.get("email")) {
      setResetToken(searchParams.get("token"));
      setEmail(searchParams.get("email"));
    } else {
      navigation("/auth/login");
    }

    return () => {
      setResetToken(null);
    };
  }, [searchParams]);

  const submitHandler = async (values: ChangePasswordPayload) => {
    mutateAsync({
      token: resetToken!,
      email: email!,
      password: values.password,
      repeat_password: values.passwordConfirm,
    })
      .then((res) => {
        if (res) {
          toast.success("Password changed successfully");
          navigation("/auth/login");
        }
      })
      .catch((err) => {
        toast.error(err.data?.message ?? "Something went wrong");
      });
  };

  return (
    <AuthLayout
      image={forgetPasswordImage}
      headerText="Change Password"
      subHeaderText="Fill the form below to change your password"
    >
      <Form
        name="change-password"
        initialValues={{ password: "", passwordConfirm: "" }}
        onFinish={submitHandler}
      >
        <Form.Item
          name="password"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input type="password" placeholder="Enter password" />
        </Form.Item>
        <Form.Item
          name="passwordConfirm"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input type="password" placeholder="Re-enter password" />
        </Form.Item>

        <Form.Item
          css={css`
            text-align: center;
          `}
        >
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={isLoading}
              css={css`
                width: 100%;
                height: 2.4rem;
                text-align: center;
                font-size: 1.2rem;
                border-radius: 1rem;
                margin-top: 1rem;
              `}
            >
              Change Password
            </Button>
            <Text
              css={css`
                width: 100%;
                margin-top: 1rem;
                display: inline-block;
                font-size: 1rem;
              `}
            >
              Don't have an account?{" "}
              <Link to="/auth/register">
                <Text
                  css={(theme: any) => css`
                    font-weight: 600;
                    color: ${theme.colorPrimary};
                  `}
                >
                  Register
                </Text>
              </Link>
            </Text>
          </Form.Item>
        </Form.Item>
      </Form>
    </AuthLayout>
  );
};
