import { proxy, useSnapshot } from "valtio";

type AddLeaveDaysDrawerState = {
  isOpen: boolean;
  leaveDaysId?: number;
};

const initialState = proxy<AddLeaveDaysDrawerState>({
  isOpen: false,
  leaveDaysId: undefined,
});

const actions = {
  open: () => {
    initialState.isOpen = true;
  },
  close: () => {
    initialState.isOpen = false;
  },
  dispatch: (leaveDaysId?: number) => {
    initialState.leaveDaysId = leaveDaysId;
    
    initialState.isOpen = true;
  },
};

export const useAddLeaveDaysDrawerState = () => {
  return useSnapshot(initialState);
};

export const useAddLeaveDaysDrawerActions = () => {
  return actions;
};
