import { Suspense } from "react";
import { Outlet, RouteObject } from "react-router-dom";
import { MainLayout } from "components/Layout";
import {
  Dashboard,
  EmployeeRoutes,
  Calendar,
  Settings,
  LeaveRequests,
  Payments,
} from "./components";

const App = () => {
  return (
    <MainLayout>
      <Suspense>
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      { path: "/", element: <Dashboard /> },
      { path: "settings", element: <Settings /> },
      {
        path: "employees/*",
        element: <EmployeeRoutes />,
      },
      {
        path: "calendar",
        element: <Calendar />,
      },
      {
        path: "payrolls",
        element: <Payments />,
      },
      {
        path: "leave-requests",
        element: <LeaveRequests />,
      },
    ],
  },
];
