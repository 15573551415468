import { useUser } from "lib/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCommonStoreActions } from "stores";
import storage from "utils/storage";

const AuthProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const { toggleDarkMode, setLimits } = useCommonStoreActions();
  const token = storage.getToken();

  const { data: user } = useUser({
    enabled: !!token,
  });

  useEffect(() => {
    if (!token) {
      if (window.location.pathname.includes("auth")) {
        return;
      }

      navigate("/auth/login");
    }
  }, []);

  useEffect(() => {
    if (user && !!token) {
 
      user?.configuration?.theme === "dark"
        ? toggleDarkMode(true)
        : toggleDarkMode(false);
    }
  }, [user, token]);

  return children;
};

export default AuthProvider;
