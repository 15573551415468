import { HomeOutlined, TeamOutlined, ClockCircleOutlined, CreditCardOutlined, RiseOutlined, CalendarOutlined, MailOutlined, SettingOutlined } from "@ant-design/icons";

export const managerMenuItems = [
    {
      icon: <HomeOutlined />,
      label: "Dashboard",
      key: "dashboard",
      path: "/",
    },
    {
      icon: <TeamOutlined />,
      label: "Employees",
      key: "employees",
      path: "/employees",
    },
    {
      icon: <ClockCircleOutlined />,
      label: "Leave Requests",
      key: "leave_requests",
      path: "/leave-requests",
    },
    {
      icon: <CreditCardOutlined />,
      label: "Payrolls",
      key: "payrolls",
      path: "/payrolls",
    },
    {
      icon: <RiseOutlined />,
      label: "Reports",
      key: "reports",
      active: false,
      path: "/reports",
    },

    {
      icon: <CalendarOutlined />,
      label: "Calendar",
      key: "calendar",
      path: "/calendar",
    },
    {
      icon: <MailOutlined />,
      label: "Mail",
      key: "mail",
      path: "/mail",
    },
    {
      icon: <SettingOutlined />,
      label: "Settings",
      key: "settings",
      path: "/settings",
    },
  ];