import { configureAuth } from "react-query-auth";

import {
  loginWithEmailAndPassword,
  getUser,
  registerWithEmailAndPassword,
  LoginCredentialsDTO,
  RegisterCredentialsDTO,
} from "pages/Auth";
import storage from "utils/storage";
import { User } from "types";
import { useQuery } from "@tanstack/react-query";
import { useCommonStoreActions } from "stores";

async function handleUserResponse(data: any) {
  const { access_token, user } = data;
  storage.setToken(access_token);
  return user;
}

async function loginFn(data: LoginCredentialsDTO) {
  const response = await loginWithEmailAndPassword(data);
  const user = await handleUserResponse(response.data);
  window.location.assign(window.location.origin as unknown as string);

  return user;
}

async function registerFn(data: RegisterCredentialsDTO) {
  const response = await registerWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function logoutFn() {
  storage.clearToken();
  window.location.assign(window.location.origin as unknown as string);
}

async function userFn() {
  const data = await getUser();
  return data as User;
}

export const useUser = (config?: any) => {
  const { toggleDarkMode, setLimits } = useCommonStoreActions();

  return useQuery<User>({
    queryKey: ["useGetUser"],
    onSuccess: (data) => {
      setLimits(data.company);
    },
    queryFn: () => {
      return userFn();
    },

    ...config,
  });
};

export const { useLogin, useLogout, useRegister, AuthLoader } = configureAuth<
  User,
  undefined,
  LoginCredentialsDTO,
  RegisterCredentialsDTO
>({
  loginFn: loginFn,
  userFn: userFn,
  registerFn: registerFn,
  logoutFn: logoutFn,
  userKey: ["user"],
});
