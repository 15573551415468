export type Indexable<T> = T & {
  [key: string]: any;
};

export type ActiveStatus = "active" | "inactive";
export type EmployeeLeaveDaysStatus = "pending" | "approved" | "rejected";

export type PaymentMethod = "Gross" | "Net";
export type PaymentTimeRange = "Annual" | "Monthly" | "Weekly" | "Daily";

export type Roles = "employee" | "manager" | "admin";

export enum PaymentRequestStatus {
  APPROVED = "approved",
  PENDING = "pending",
  REJECTED = "rejected",
}
export enum Priority {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
}

export type LeaveType =
  | "annual"
  | "sick"
  | "maternity"
  | "paternity"
  | "compassionate"
  | "study"
  | "unpaid"
  | "other";

export type Limit = {
  name: string;
  slug: string;
  value: number;
  description: string;
  plan_id: number;
};

export type StripePlan = {
  name: string;
  slug: string;
  limits: Limit[];
};

export type Company = {
  id: number;
  name: string;
  email: string;
  country?: string;
  city: string;
  address?: string;
  phone?: string;
  status: "active" | "inactive";
  currency?: string;
  timezone?: string;
  default_worker_count?: string;
  plan: StripePlan;
  branches_count: number;
  departments_count: number;
  users_count: number;
  logo: string;
};

export type Branch = Company & {
  company_id: number;
};

export type EmploymentType = {
  id: number;
  name: string;
  slug: string;
  description: string;
  status: string;
};

export type Employee = {
  id: number;
  name: string;
  surname: string;
  identityNumber: null | string;
  email: string;
  phone: null | string;
  employmentType: EmploymentType;
  start_date: string | Date;
  end_date: null | string;
  note: null | string;
  company: Company;
  birthdate: string | Date;
  department: Department;
  notes: {
    id: number;
    note: string;
    created_at: string;
    updated_at: string;
  }[];
};

export type Department = {
  id: number;
  name: string;
  slug: string;
  description: string;
  status: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  jobs: any[]; // You might want to replace 'any[]' with the actual type for jobs if you have that information
};

export type leavedays = {
  id: number;
  name: string;
  slug: string;
  description: string;
  default_days: number;
  created_at: string;
  updated_at: string;
};

export type Payment = {
  id: number;
  name: "Salary" | "Bonus" | "Reimbursement" | "Advance";
  description: string;
  created_at: string;
  updated_at: string;
};

export type EmployeeDocument = {
  file_path: string;
  file_name: string;
  file_size: number;
  file_type: string;
  file_extension: string;
  user_id: number;
  updated_at: string;
  created_at: string;
  id: number;
};

/* {
    "id": 121,
    "user_id": 16,
    "leave_days_id": 1,
    "unlimited_days": false,
    "used_days": 11,
    "remaining_days": 9,
    "company_id": 1,
    "created_at": "2024-09-25T10:14:33.000000Z",
    "updated_at": "2024-11-06T08:51:28.000000Z",
    "leave_days": {
        "id": 1,
        "name": "Annual"
    }
} */

export type LeaveDayResponse = {
  id: number;
  start_date: Date;
  end_date: Date;
  days: number;
  note: string;
  attachment: string;
  return_date: Date;
  status: EmployeeLeaveDaysStatus;
  replacement_user_id: number;
  company_id: number;
  user_id: number;
  user_leave_day_id: number;
  approved_by: number;
  rejected_by: number;
  requested_by: number;
  requested_at: Date;
  approved_at: Date;
  rejected_at: Date;
  user: Employee;
  user_leave_day: {
    leave_days: leavedays;
    id: number;
    remaining_days: number;
    user_id: number;
  };
  created_at: Date;
  updated_at: Date;
};

export type PaymentRequest = {
  id: number;
  user_id: number;
  user: Employee;
  payment_type_id: number;
  payment_type: Payment;
  amount: number;
  currency: string;
  description: string;
  attachment: string;
  status: PaymentRequestStatus;
  payment_method: string;
  payment_details: string;
  approved_by: number;
  approved_at: string;
  rejected_by: number;
  rejected_at: string;
  created_at: string;
  updated_at: string;
};

export interface Holiday {}

export type Configurations = {
  timezone: string;
  language: string;
  theme: string;
};

export type User = {
  id: number;
  name: string;
  surname: string;
  email: string;
  phone: string;
  city: string;
  role: Roles;
  configuration: Configurations;
  company: Company;
};
