import { Route, Routes } from "react-router-dom";
import { ChangePassword } from "./ChangePassword";
import { EmailConfirmation } from "./EmailConfirmation";
import { Login } from "./Login";
import { Register } from "./Register";
import { ResetPassword } from "./ResetPassword";
import { useEffect } from "react";
import { useCommonStoreActions } from "stores";

export const AuthRoutes = () => {
  const { toggleDarkMode } = useCommonStoreActions();
 
  useEffect(() => {
    toggleDarkMode(false);
  }, [toggleDarkMode]);
  return (
    <Routes>
      <Route path="register" element={<Register />} />
      <Route path="login" element={<Login />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="email-confirmation" element={<EmailConfirmation />} />
      <Route path="change-password" element={<ChangePassword />} />
      <Route path="*" element={<Login />} />
    </Routes>
  );
};
