import { defineAbility } from "@casl/ability";
import { Roles } from "types";

const generateEmployeePermissions = (can: any, cannot: any) => {
  cannot("show", "LeaveDaysCard");
  cannot("show", "PaymentsCard");
  cannot("approve", "LeaveDays");
  cannot("show", "EmployeesPage");
};

const generateManagerPermissions = (can: any, cannot: any) => {
  can("show", "LeaveDaysCard");
  can("show", "PaymentsCard");
  can("approve", "LeaveDays");
  can("show", "EmployeesPage");
};

export default function generatePermissions(role: Roles) {
  return defineAbility((can, cannot) => {
    role === "employee"
      ? generateEmployeePermissions(can, cannot)
      : generateManagerPermissions(can, cannot);
  });
}
