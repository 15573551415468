import { useExtendedMutation } from "hooks";
import { axiosMain } from "lib/axios";
import { MutationConfig } from "lib/react-query";

export type ChangePasswordPayload = {
  resetToken: string;
  password: string;
  passwordConfirm: string;
};

export const changePassword = async (payload: ChangePasswordPayload) => {
  return axiosMain.post("/auth/change-password", payload);
};

type QueryFnType = typeof changePassword;

export const useChangePassword = (config?: MutationConfig<QueryFnType>) => {
  return useExtendedMutation({
    ...config,
    mutationFn: changePassword,
  });
};
