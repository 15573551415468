import { Company, Limit } from "types";
import { proxy, useSnapshot } from "valtio";

export type State = {
  isDarkMode: boolean;
  canAddBranch: boolean;
  canAddDepartment: boolean;
  canAddEmployee: boolean;
};

const initialState = proxy<State>({
  isDarkMode: false,
  canAddBranch: true,
  canAddDepartment: true,
  canAddEmployee: true,
});

const actions = {
  toggleDarkMode: (isDarkMode: boolean) => {
    initialState.isDarkMode = isDarkMode;
  },
  setLimits: (company: Company) => {
 
    company?.plan?.limits?.forEach((limit: Limit) => {
      if (limit.slug === "branch_count") {
        initialState.canAddBranch = limit.value > company.branches_count;
      }
      if (limit.slug === "department_count") {
        initialState.canAddDepartment = limit.value > company.departments_count;
      }
      if (limit.slug === "employee_count") {
        initialState.canAddEmployee = limit.value > company.users_count;
      }
    });
  },
};

export const useCommonStoreState = () => useSnapshot(initialState);
export const useCommonStoreActions = () => actions;
