import { Skeleton, Row, Col, Modal } from "antd";
import { LeaveDayStatusTag } from "components";
import { RenderIf, InfoRow } from "components/Common";
import dayjs from "dayjs";
import { useGetLeaveDaysRequest } from "pages/LeaveRequests/api";
import {
  useLeaveDaysDetailsModalActions,
  useLeaveDaysDetailsModalState,
} from "pages/LeaveRequests/stores";
import { useEffect } from "react";

export const LeaveDaysDetailsModal = () => {
  const { isOpen, id, onlyView } = useLeaveDaysDetailsModalState();
  const { close, dispatch } = useLeaveDaysDetailsModalActions();

  const handleApprove = () => {
    console.log(id);
  };

  const { data: leaveDay, isLoading } = useGetLeaveDaysRequest(
    { id: id! },
    { enabled: isOpen }
  );

  useEffect(() => {
    /* listen url for leave_day_request param*/
    const urlParams = new URLSearchParams(window.location.search);
    const leaveDayRequestId = urlParams.get("leave_day_request");
    if (leaveDayRequestId) {
      dispatch({ id: leaveDayRequestId });
    }
  }, []);

  return (
    <Modal
      open={isOpen}
      onCancel={close}
      title="Leave Day Details"
      destroyOnClose
      width={600}
      okText="Approve"
      cancelText="Cancel"
      onOk={handleApprove}
      footer={onlyView ? null : undefined}
    >
      <RenderIf
        condition={!isLoading && !!leaveDay}
        fallback={<Skeleton active paragraph={{ rows: 4 }} />}
      >
        <Row justify="center" gutter={[16, 16]}>
          <Col span={24}>
            <InfoRow
              text="Employee"
              content={leaveDay?.user?.name + " " + leaveDay?.user?.surname}
            />
          </Col>
          <Col span={12}>
            <InfoRow text="Reason" content={leaveDay?.user_leave_day?.leave_days?.name} />
          </Col>
          <Col span={12}>
            <InfoRow text="Days Taken" content={leaveDay?.days} />
          </Col>
          <Col span={12}>
            <InfoRow
              text="Start Date"
              content={dayjs(leaveDay?.start_date).format("DD/MM/YYYY")}
            />
          </Col>
          <Col span={12}>
            <InfoRow
              text="End Date"
              content={dayjs(leaveDay?.end_date).format("DD/MM/YYYY")}
            />
          </Col>
          <Col span={12}>
            <InfoRow
              text="Return Date"
              content={dayjs(leaveDay?.return_date).format("DD/MM/YYYY")}
            />
          </Col>
          <Col span={12}>
            <InfoRow
              text="Status"
              content={<LeaveDayStatusTag status={leaveDay?.status} />}
            />
          </Col>
          <Col span={24}>
            <InfoRow text="Description" content={leaveDay?.note} />
          </Col>
        </Row>
      </RenderIf>
    </Modal>
  );
};
