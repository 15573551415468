import { axiosMain } from "lib/axios";
import storage from "utils/storage";
import { User } from "types";

export const getUser = async (): Promise<User> => {
  const response = await axiosMain.get("/auth/me").catch((err) => {
    storage.clearToken();
  });

  return response?.data;
};
